import { Component } from '@angular/core';
import { cdnUri } from "../../../core/helpers/app.helper";

@Component({
  selector: 'app-onboarding-layout',
  templateUrl: './onboarding-layout.component.html',
  styleUrl: './onboarding-layout.component.scss'
})
export class OnboardingLayoutComponent {

  protected readonly cdnUri = cdnUri;
}
