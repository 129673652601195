<div class="h-100 bg-floor">
  <div class="h-100 wrapper d-flex flex-column justify-content-between">
    <div class="logo-container align-self-center d-flex justify-content-center my-5">
      <img src="{{cdnUri('assets/logo/gks-dark.svg')}}" alt="Logo">
    </div>

    <div class="main-wrapper h-100 d-flex flex-column align-items-center justify-content-start px-4" style="gap: 20px;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
